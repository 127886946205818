import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const TermsPage = () => (
  <Layout>
    <Seo title="Disclaimer" />
    <div className="bg-gray-50 py-12 pt-36">
      <div className="w-11/12 max-w-2xl mx-auto">
        <h1 className="text-3xl text-site-black text-center font-bold mb-6">Disclaimer</h1>

        <h2 className='text-xl text-gray-800 font-semibold mb-4'>General Disclaimer</h2>

        <p className='text-gray-800 mb-4'>This website and the Shitecoin ($SHITE) token are provided "as is" without any warranties of any kind. Shitecoin ($SHITE) is a meme coin with no intrinsic value or expectation of financial return. This is a parody meme coin. </p>

        <h2 className='text-xl text-gray-800 font-semibold mb-4'>Token Classification</h2>

        <p className='text-gray-800 mb-4'>Nature of Token</p>
        
        <ul className="list-inside list-disc ml-3 mb-4">
          <li className='text-gray-800 mb-4'>This token is purely for entertainment purposes</li>
          <li className='text-gray-800 mb-4'>No formal team, roadmap, or whitepaper exists</li>
          <li className='text-gray-800 mb-4'>The token has no utility or guaranteed value</li>          
        </ul>

        <h2 className='text-xl text-gray-800 font-semibold mb-4'>Risk Disclosure</h2>

        <p className='text-gray-800 mb-4'>Investment Risks</p>
        
        <ul className="list-inside list-disc ml-3 mb-4">
          <li className='text-gray-800 mb-4'>Cryptocurrency investments are highly speculative and volatile</li>
          <li className='text-gray-800 mb-4'>Users may lose their entire investment</li>
          <li className='text-gray-800 mb-4'>Past performance does not indicate future results</li>          
        </ul>

        <h2 className='text-xl text-gray-800 font-semibold mb-4'>Not Financial Advice</h2>

        <p className='text-gray-800 mb-4'>The information provided on this website does not constitute financial, investment, or legal advice. Users should conduct their own research and consult qualified professionals before making any investment decisions.</p>

        <h2 className='text-xl text-gray-800 font-semibold mb-4'>Liability Limitations</h2>

        <p className='text-gray-800 mb-4'>Under no circumstances shall the website owners, developers, or contributors be liable for any:</p>
        
        <ul className="list-inside list-disc ml-3 mb-4">
          <li className='text-gray-800 mb-4'>Direct or indirect losses</li>
          <li className='text-gray-800 mb-4'>Investment decisions made by users</li>
          <li className='text-gray-800 mb-4'>Technical failures or security breaches</li>
          <li className='text-gray-800 mb-4'>Tax obligations or regulatory compliance issues</li>          
        </ul>

        <h2 className='text-xl text-gray-800 font-semibold mb-4'>Regulatory Notice</h2>

        <p className='text-gray-800 mb-4 italic'>**User Responsibilities**</p>
        
        <ul className="list-inside list-disc ml-3 mb-4">
          <li className='text-gray-800 mb-4'>Users must verify compliance with their local laws and regulations</li>
          <li className='text-gray-800 mb-4'>Users are solely responsible for determining applicable tax obligations</li>
          <li className='text-gray-800 mb-4'>No guarantees are made regarding the token's legal status in any jurisdiction</li>          
        </ul>

        <h2 className='text-xl text-gray-800 font-semibold mb-4'>Market Disclaimer</h2>

        <p className='text-gray-800 mb-4'>The token value may experience significant fluctuations. There is no guarantee of:</p>
        
        <ul className="list-inside list-disc ml-3 mb-4">
          <li className='text-gray-800 mb-4'>Market demand</li>
          <li className='text-gray-800 mb-4'>Liquidity</li>
          <li className='text-gray-800 mb-4'>Future utility</li>  
          <li className='text-gray-800 mb-4'>Price stability</li>          
        </ul>

        <h2 className='text-xl text-gray-800 font-semibold mb-4'>Ownership Rights</h2>

        <p className='text-gray-800 mb-4'>All content on this website is protected by creative commons copyright laws. Users may only view and use the content for personal, non-commercial purposes.</p>
      </div>
    </div>
  </Layout>
)

export default TermsPage
