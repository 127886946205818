import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="bg-site-yellow w-full h-screen flex flex-col items-center justify-center">
      <h1 className="text-4xl text-site-black font-bold mb-8">404: Not Found</h1>
      <p className="text-xl text-site-black font-medium mb-8">You just hit a route that doesn&#39;t exist... the sadness.</p>
      <p className="text-xl text-site-black font-medium">Go back to <Link to="/" className="hover:underline">Home page..</Link></p>
    </div>      
  </Layout>
)

export default NotFoundPage
